import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'

import Filters from './Filter';
import Selector from '../Selector';
import { ActionTypes } from '../../store/types';
import {
    AllOptionsGroup,
    AllSearchTypes,
    AllSortOrders,
    AllSortsGroup
} from '../../utils/consts';
import { useEffect } from 'react';
import { tabletSmall } from '../../styles';

export default () => {

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const searchType = useSelector(state => state.searchType);
    const [searchTypeIndex, setIndex] = useState(0);

    useEffect(() => {
        AllSearchTypes.map((type, index) => {
            if (searchType === type)
                setIndex(index);
        })
    }, [searchType]);

    const AllOptions = AllOptionsGroup[searchTypeIndex];
    const AllSorts = AllSortsGroup[searchTypeIndex];
    const allOptions = AllOptions.map(option => option.displayName);
    const allSorts = AllSorts.map(sort => sort.displayName);

    const sortBy = AllSorts.find(sort => sort.dataField === state.sortBy[searchTypeIndex]).displayName;

    const onAddFilter = (optionName) => {
        const option = AllOptions.find(option => option.displayName === optionName);
        const filterList = state.searchOptions[searchTypeIndex].map(item => item.displayName);
        if (!filterList.includes(optionName))
            dispatch({
                type: ActionTypes.ON_ADD_FILTER,
                filter: { ...option }
            });
    };
    const onSortBy = (sortBy) => {
        const sort = AllSorts.find(sort => sort.displayName === sortBy);
        dispatch({
            type: ActionTypes.ON_SORT_BY,
            sortBy: sort.dataField
        })
    }
    return (
        <Container>
            <Block>
                <Selector
                    title="Operator"
                    selects={["AND", "OR"]}
                    selectName={state.searchOperator[searchTypeIndex]}
                    onSelect={searchOperator => dispatch({
                        type: ActionTypes.ON_SEARCH_OPERATOR,
                        searchOperator
                    })}
                />
            </Block>

            <Block>
                <Selector
                    title="Sort By"
                    selects={allSorts}
                    selectName={sortBy}
                    onSelect={onSortBy}
                />
            </Block>

            <Block>
                <Selector
                    title="Sort Order"
                    selects={AllSortOrders}
                    selectName={state.sortOrder[searchTypeIndex]}
                    onSelect={sortOrder => dispatch({
                        type: ActionTypes.ON_SORT_ORDER,
                        sortOrder
                    })}

                />
            </Block>
            <Block>
                <Filters searchTypeIndex={searchTypeIndex} />
                <Selector
                    title="Add Filter"
                    selects={allOptions}
                    onSelect={onAddFilter}
                />
            </Block>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 20px;
    background-color: var(--green-primary);
    overflow: auto;
    width: 100%;
    height: 100%;
    ::-webkit-scrollbar{
        width: 0px;
        height: 4px;
    }
    ::-webkit-scrollbar-thumb{
        border-radius: 3px;
        background: #c7c4c4;
    }
    border-radius: 0 0 16px 0;
    ${tabletSmall} {
        border-radius: 0;
    }
`
const Block = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
