import { init } from "../store/reducer";

export const readLocalData = async () => {
  try {
    const json = window.localStorage.getItem("storage");
    if (!json) {
      writeLocalData(init.user);
      return init.user;
    }
    return JSON.parse(json)
  } catch (ex) {
    console.error(ex);
  }
}

export const writeLocalData = (state) => {
  try {
    window.localStorage.setItem("storage", JSON.stringify(state));
  } catch (ex) {
    console.error(ex);
  }
}
