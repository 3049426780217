import { apiPost } from "./api-template";

export const onSearch = ({ searchTerms, searchOperator, sortBy, sortOrder, searchOptions, searchType }) => {
    return apiPost("/search", {
        searchTerms,
        searchOperator,
        sortBy,
        sortOrder,
        searchOptions,
        searchType
    });
}