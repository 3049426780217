import React from 'react';
import { useSelector } from 'react-redux';

import { BooleanCard, DateRangeCard, RangeCard, SelectCard } from './FilterCards';
import { DataType } from '../../../utils/consts';

export default (props) => {
    const { searchTypeIndex } = props;
    const state = useSelector(state => state);
    const filters = state.searchOptions[searchTypeIndex];

    return (
        <div>
            {filters.map((filter, index) => {
                switch (filter.dataType) {
                    case DataType.BOOLEAN: {
                        return (
                            <div key={filter.dataField}>
                                <BooleanCard
                                    data={filter}
                                    index={index}
                                />
                            </div>
                        )
                    }

                    case DataType.VALUE_RANGE: {
                        return (
                            <div key={filter.dataField}>
                                <RangeCard
                                    data={filter}
                                    index={index}
                                />
                            </div>
                        )
                    }

                    case DataType.DATE_RANGE: {
                        return (
                            <div key={filter.dataField}>
                                <DateRangeCard
                                    data={filter}
                                    index={index}
                                />
                            </div>
                        )
                    }

                    case DataType.SELECT: {
                        return (
                            <div key={filter.dataField}>
                                <SelectCard
                                    data={filter}
                                    index={index}
                                />
                            </div>
                        )
                    }
                    default: {
                        return (<></>)
                    }
                }
            })}
        </div>
    )
}