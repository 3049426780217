import styled from 'styled-components';

const Button = (props) => {
    const { text, onClick, small } = props;
    return (
        <ButtonStyle onClick={onClick} {...props}>
            {text}
        </ButtonStyle>
    );
}
export default Button;

export const ButtonStyle = styled.button`
    diplay: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;  
    width: "70px";
    height: 36px;
    border: 0;
    box-sizing: border-box;
    border-radius: 18px;
    background-color: var(--shade-7);
    color: #000;
    padding: 0 18px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.15s ease-in-out;

    cursor: pointer;  
    :hover {
        filter: brightness(130%);
    }

    :active {
        filter: brightness(80%);
    }

    :disabled {
        filter: brightness(40%);
    }
`;
