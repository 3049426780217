import { useState } from "react";
import styled from "styled-components";
import { useDispatch } from 'react-redux';

import * as AmplifyApi from '../../api/login-api'
import { ActionTypes } from "../../store/types";
import PasswordShowIcon from "../PasswordShowIcon";

const Login = (props) => {
    const dispatch = useDispatch();
    const { isAdmin, setIsLogined } = props;
    const [showPass, setShowPass] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isWaiting, setIsWaiting] = useState(false);
    const [err, setErr] = useState("");

    const onLogin = () => {
        setIsWaiting(true);
        AmplifyApi.signIn({ email, password, isAdminLogin: isAdmin })
            .then((response) => response.json().then((json) => {
                setIsWaiting(false);

                if (!response.ok) {
                    console.log("Reason:", response);
                    setErr(json.code || "Sign in Error!")
                    return;
                }

                if (isAdmin) {
                    setIsLogined(true);
                } else {
                    dispatch({
                        type: ActionTypes.ON_SIGN_IN,
                        user: {
                            isLogined: true,
                            email: email,
                            password: password
                        }
                    });
                }
            }))
            .catch(reason => {
                setIsWaiting(false);
                console.log("Reason:", reason);
                setErr(reason.code || "Sign in Error!")
            });
    }
    return (
        <Container>
            <Header>
                {!isAdmin ?
                    <>
                        {/*<Logo src="img/kangaroo-svgrepo-com.svg" />*/}
                        <h1>YourIndex Research</h1>
                    </> :
                    <h1>Backstage Management</h1>
                }
            </Header>
            <Body>
                <CardHeader>
                    <Title>
                        {!isAdmin ?
                            "Welcome Back!" :
                            "Administrator Sign in"
                        }
                    </Title>
                    <Err>
                        {err}
                    </Err>
                </CardHeader>
                <Content>
                    <Email>
                        <Input
                            type={"text"}
                            placeholder="Email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setErr("");
                            }}
                        />
                    </Email>
                    <Password>
                        <Input
                            type={showPass ? "text" : "password"}
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErr("");
                            }}
                        />
                        <PasswordShowIcon
                            showPass={showPass}
                            setShowPass={setShowPass}
                        />
                    </Password>
                    <SignInButton onClick={() => onLogin()}>
                        {isWaiting ? "Signing in ..." : "Sign in"}
                    </SignInButton>
                </Content>
            </Body>
            <Footer>
                <span>
                    &copy; All Rights Reserved
                </span>
            </Footer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    gap: 20px;
`
const Header = styled.div`
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--green-primary);
    margin: 0;
    padding: 17px 0;
    box-sizing: border-box;
    color: #fff;
    height: 90px;
`
const Footer = styled.div`
    text-align: center;
`
const Body = styled.div`
    width: 480px;
    background-color: #fff;
    box-shadow: 0px 2px 6px hsla(210, 50%, 10%, 0.15);
    border: solid 1px var(--sign-border);
    box-sizing: border-box;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 202px;
    padding: 12px 32px 32px 32px;
    gap: 16px;
`
const Title = styled.p`
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 600;
    color: #000;
`
const CardHeader = styled.div`
    width: 100%;
    height: 100px;
    margin: 0;
    padding: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Err = styled.p`
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    color: red;
`
const Input = styled.input`
    box-sizing: border-box;
    padding: 8px 16px;
    width: 100%;
    height: 42px;
    border: solid 1px var(--sign-border);
    border-radius: 0.25rem;
    user-select: text;
`
const Email = styled.div`
`
const Password = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`
const Logo = styled.img`
    width: 56px;
`
const SignInButton = styled.div`
    cursor: pointer;
    background-color: var(--sign-button-primary);
    color: #fff;
    width: 100%;
    height: 42px;
    border-radius: 0.25rem;
    box-sizing: border-box;
    padding: 8px 16px;
    border: solid 1px parent;
    text-align: center;
    font-weight: 600;
`

export default Login;