import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { exportCSV } from '../../utils';
import * as SearchApi from '../../api/search-api';
import { ActionTypes } from '../../store/types';
import { KeyGrops, allIndices, AllSearchTypes } from '../../utils/consts';
import { transformData } from '../../utils/index'
import { desktop, tabletLarge, tabletMedium, tabletSmall } from '../../styles';
import Selector from '../Selector';
import { CircleButton } from '../Button';
import SearchOptions from '../SearchOptions';

const SearchBar = () => {
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState("");
    const state = useSelector(state => state);
    const searchType = useSelector(state => state.searchType);
    const [searchTypeIndex, setIndex] = useState(0);
    const [uniqueStocks, setUniqueStocks] = useState(0);
    const exportData = useSelector(state => state.result[searchTypeIndex]);
    let uniqueArray = [];
    const [showMobileFilter, setShowMobileFilter] = useState(false);

    useEffect(() => {
        AllSearchTypes.map((type, index) => {
            if (searchType === type) {
                setIndex(index);
                if (state.result[index])
                    setKeyword(state.searchTerms[index][state.searchTerms[index].length - 1]);
                else
                    setKeyword("");
            }
        });
    }, [searchType]);

    useEffect(() => {
        let tickerList = [];
        if (exportData)
            exportData.map((_data) => {
                tickerList.push(_data.ticker);
            })
        uniqueArray = [...new Set(tickerList)];
        setUniqueStocks(uniqueArray.length);
    }, [exportData]);

    const onSearch = () => {
        dispatch({
            type: ActionTypes.ON_WAITING,
            isWaiting: true
        });
        SearchApi.onSearch({
            searchTerms: keyword,
            searchOperator: state.searchOperator[searchTypeIndex],
            sortBy: state.sortBy[searchTypeIndex],
            sortOrder: state.sortOrder[searchTypeIndex],
            searchOptions: state.searchOptions[searchTypeIndex],
            searchType: state.searchType
        }).then(result => result.json().then(json => {
            const data = transformData(json);
            dispatch({
                type: ActionTypes.ON_SEARCH,
                data: { result: data, searchTerms: keyword }
            });

            dispatch({
                type: ActionTypes.ON_WAITING,
                isWaiting: false
            });
        })).catch(reason => {
            console.log(reason)
            dispatch({
                type: ActionTypes.ON_WAITING,
                isWaiting: false
            });
        });
    }

    const handleKeyup = (event) => {
        if (event.keyCode === 13 && onSearch) {
            onSearch();
        }
    };

    const onExport = () => {
        if (exportData.length > 0) {
            const headers = KeyGrops.Default?.map(card => (
                card?.map(item => {
                    if (item.dataField === "indices")
                        return {
                            dataField: "indices",
                            displayName: allIndices
                        }
                    else
                        return item;
                })
            ));

            switch (searchTypeIndex) {
                case 0: {
                    headers.unshift(KeyGrops.Announcement);
                    exportCSV(exportData, headers);
                    return;
                }
                case 1: {
                    headers.unshift(KeyGrops.Company);
                    exportCSV(exportData, headers);
                    return;
                }
                case 2: {
                    headers.unshift(KeyGrops.Director);
                    exportCSV(exportData, headers);
                    return;
                }
            }
        }

    }

    return (
        <>
            <Container>
                <Content>
                    <Search>
                        <Input
                            placeholder="Enter Search Terms..."
                            onChange={e => setKeyword(e.target.value)}
                            value={keyword}
                            onKeyUp={handleKeyup}
                        />
                        <SelectList>
                            <Divider />
                            <Selector
                                title=""
                                selects={AllSearchTypes}
                                selectName={searchType}
                                onSelect={
                                    (select) => dispatch({
                                        type: ActionTypes.ON_SET_SEARCH_TYPE,
                                        searchType: select
                                    })
                                }
                                isModal={true}
                                isSearchBar={true}
                            />
                        </SelectList>
                    </Search>
                    <ButtonGroup>
                        <CircleButton
                            img="img/search.svg"
                            onClick={onSearch}
                        />
                        <CircleButton
                            img="img/export.svg"
                            onClick={onExport}
                        />
                        <CircleButton
                            img="img/chart.svg"
                            onClick={() => console.log("chart")}
                        />
                        <MobileFilterButton
                            img="img/mobile-filterlist.svg"
                            onClick={() => setShowMobileFilter(!showMobileFilter)}
                        />
                    </ButtonGroup>
                </Content>
                <Numbers>
                    <span>
                        Result: {state.result[searchTypeIndex]?.length || 0}
                    </span>
                    {!(searchType === AllSearchTypes[1]) &&
                        <span>
                            {searchType === AllSearchTypes[0] ? "Unique Stocks: " : "Companies: "}
                            {uniqueStocks}
                        </span>
                    }
                </Numbers>
            </Container>
            {showMobileFilter &&
                <MobileFilter>
                    <SearchOptions />
                </MobileFilter>
            }
        </>
    )
}

const Container = styled.div`
    height: 75px;
    ${tabletSmall} {
        height: 110px;
        width: 100%;
        align-items: flex-end;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${desktop} {
        justify-content: start;
    }
    align-items: center;
    position: relative;
`
const Numbers = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    color: var(--shade-7);
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    right: 40px;
    ${tabletSmall} {
        right: 0;
    }
    margin: 0;
`
const Content = styled.div`
    display: flex;
    flex-direction: row;
    ${tabletSmall} {
        flex-direction: column;
        width: 100%;
        gap: 20px;
        align-items: flex-start;
    }
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
`
const Input = styled.input`
    box-sizing: border-box;
    width: 70%;
    height: 40px;
    border: 0px;
    border-radius: 20px;
    background: var(--shade-7);
    color: var(--shade-0);
    align-items: center;
    padding: 0 20px;
    font-weight: 400;
    font-size: 16px;
    outline: none;
`
const Search = styled.div`
    width: 700px;
    ${tabletLarge} {
        width: 500px;
    }
    ${tabletMedium} {
        width: 400px;
    }
    ${tabletSmall} {
        width: 100%;
    }
    display: flex;
    box-sizing: border-box;
    height: 40px;
    border: 0px;
    border-radius: 20px;
    background: var(--shade-7);
    align-items: center;
    justify-content: space-between;
`
const SelectList = styled.div`
    height: 40px;
    border: 0px;
    border-radius: 20px;
    background: var(--shade-7);
    color: var(--shade-0);
    align-items: center;
    display: flex;
`
const Divider = styled.div`
    width: 1px;
    height: 22px;
    background-color: var(--shade-0);
`
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`
const MobileFilterButton = styled(CircleButton)`
    display: none;
    ${tabletSmall} {
        display: flex;
    }
`
const MobileFilter = styled.div`
    display: none;
    width: 100%;
    height: calc(100vh - 200px);
    box-sizing: border-box;
    position: absolute;
    top: 200px;
    left: 0;
    z-index: 99;
    ${tabletSmall} {
        display: block;
    }
`;

export default SearchBar;