export const apiGet = (url) => {

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    url = process.env.REACT_APP_ENDPOINT + url;
    return fetch(url, options);
}

export const apiPost = (url, body) => {

    const options = {
        method: 'POST',

        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    };

    url = process.env.REACT_APP_ENDPOINT + url;
    return fetch(url, options);
}

export const apiUpdate = (url, body) => {

    const options = {
        method: 'PUT',

        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    };

    url = process.env.REACT_APP_ENDPOINT + url;
    return fetch(url, options);
}
