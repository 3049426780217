import styled from "styled-components"

const PasswordShowIcon = (props) => {
    const { showPass, setShowPass, top } = props;
    return (
        <PassShowButton
            onClick={() => setShowPass(!showPass)}
            top={top}
        >
            {showPass ?
                <Img src="/img/eye.svg" /> :
                <Img src="/img/hiddenEye.svg" />}
        </PassShowButton>
    )
}

const PassShowButton = styled.div`
    position: absolute;
    right: 15px;
    display: flex;
    align-items: center;
    height: 42px;
    top: ${props => props?.top};
`
const Img = styled.img`
    cursor: pointer;
    width: 16px;
    height: 16px;
`

export default PasswordShowIcon;