import styled from "styled-components"

import { Button } from "./Button";

const Table = (props) => {
    const { headers, userList, onEdit, onDelete } = props;

    return (
        <Container>
            <THead>
                <HeaderContainer>
                    {headers && headers.map((header) => (
                        <HeaderItem key={header.dataField}>
                            {header.displayName}
                        </HeaderItem>
                    ))}
                </HeaderContainer>
            </THead>
            <TBody>
                {userList && userList.map((user, index) =>
                    <Row key={index}>
                        {headers.map((header) => (
                            <Cell key={header.dataField}>
                                {header.dataField !== "edit"
                                    ? <>
                                        {user.Attributes.map(attr => (
                                            attr.Name === header.dataField
                                                ? attr.Value
                                                : ''
                                        ))}
                                    </>
                                    : <Buttons>
                                        <Button
                                            text="Edit"
                                            onClick={() => onEdit(user)}
                                        />
                                        <Button
                                            text="Delete"
                                            onClick={() => onDelete(user)}
                                        />
                                    </Buttons>
                                }
                            </Cell>
                        ))}
                    </Row>
                )}
            </TBody>
        </Container>
    )
}

const Container = styled.table`
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #000;
`
const HeaderContainer = styled.tr`
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 0;
    border-bottom: 1px solid #fff;
    align-items: center;
    color: #fff;
    font-size: 16px;
    th:first-child {
        border-left: 0px;
    }
    position: sticky;
    top: 0;
    background-color: var(--green-primary);
`
const THead = styled.thead`
    width: 100%;
`
const TBody = styled.tbody`
    width: 100%;
    box-sizing: border-box;
    padding: 0;
`
const HeaderItem = styled.th`
    align-items: center;
    height: 35px;
    padding: 0 20px;
    border-left: 1px solid;
`
const Row = styled.tr`
    td:first-child {
        border-left: 0px;
    }
    height: 50px;
    border-bottom: 1px solid #fff;
    background-color: var(--admin-cardbody-primary);
`
const Cell = styled.td`
    padding: 0 20px;
    text-align: left;
    vertical-align: center !important;
    border-left: 1px solid;
    border-color: #fff;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
`
const Buttons = styled.div`
    display: flex;
    gap: 10px;
`

export default Table;