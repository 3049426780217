import styled, { createGlobalStyle } from 'styled-components';
import ReactModal from 'react-modal';
import { Button } from '../../component/Button';
import Input from '../../component/Input'
import Selector from '../../component/Selector';
import PasswordShowIcon from '../../component/PasswordShowIcon';
import { useState } from 'react';

ReactModal.setAppElement("#root");

const Modal = (props) => {
    const { showModal,
        setShowModal,
        userData,
        onUpdate,
        onAdd,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        role,
        setRole,
        email,
        setEmail,
        password,
        setPassword
    } = props;
    const [showPass, setShowPass] = useState(false);

    return (
        <>
            <ModalStyles />
            <ReactModal
                isOpen={showModal}
                overlayClassName="myoverlay"
                className="mycontent"
            >
                <Container>
                    <Header>
                        {userData ? "Edit User" : "Add User"}
                    </Header>
                    <Body>
                        <Content>
                            <Input
                                title="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <Input
                                title="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <Selector
                                title="Role"
                                selects={["User", "Admin"]}
                                selectName={role}
                                onSelect={select => setRole(select)}
                                isModal={true}
                            />
                            <Input
                                title="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={userData ? true : false}
                            />
                            {!userData &&
                                <PasswordBar>
                                    <Input
                                        title="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPass ? "text" : "password"}
                                    />
                                    <PasswordShowIcon
                                        showPass={showPass}
                                        setShowPass={setShowPass}
                                        top={"15px"}
                                    />
                                </PasswordBar>
                            }
                        </Content>
                        <Buttons>
                            <Button
                                text="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
                                onClick={() => {
                                    if (userData) {
                                        onUpdate();
                                    } else {
                                        onAdd();
                                    }
                                }}
                            />
                            <Button
                                text="Cancel"
                                onClick={() => setShowModal(false)}
                            />
                        </Buttons>
                    </Body>
                </Container>
            </ReactModal>
        </>
    );
}

const ModalStyles = createGlobalStyle`
    .myoverlay {
        z-index: 10;
    } 
    .mycontent {
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;    
        background-color: rgba(255, 255, 255, 0.5);
        padding: var(--padding);
    }
`
const Container = styled.div`
    width: 500px;
`
const Buttons = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`
const Header = styled.div`
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    padding: 0 20px;
    background-color: var(--green-primary);
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
`
const Body = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: var(--admin-cardbody-primary);
    padding: 20px;
    gap: 20px;
`
const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 30px;
`
const PasswordBar = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`

export default Modal;