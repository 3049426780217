import { useDispatch } from 'react-redux';

import * as AmplifyApi from '../../api/login-api'
import { Button } from "../Button";
import { ActionTypes } from "../../store/types";

const LogOut = (props) => {
    const { setIsLogined } = props;
    const dispatch = useDispatch();

    const onLogOut = () => {
        AmplifyApi.signOut()
            .then(_result => {
                if (setIsLogined) {
                    setIsLogined(false);
                }
                else {
                    dispatch({
                        type: ActionTypes.ON_SIGN_OUT,
                        user: {
                            isLogined: false,
                            email: "",
                            password: ""
                        }
                    });
                }
            })
            .catch(reason => {
                console.log(reason);
            });
    }

    return <Button
        text="Sign Out"
        onClick={() => onLogOut()}
    />
}

export default LogOut;