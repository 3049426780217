import React from 'react';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

import { Login, Logout } from '../../component/Login';
import { Button } from '../../component/Button';
import { headerFormat } from '../../utils/consts';
import Modal from './Modal';
import VerificationModal from './VerificationModal';
import * as AmplifyApi from '../../api/login-api';
import Table from '../../component/Table'

const Admin = () => {
    const [isLogined, setIsLogined] = useState(false);
    const [userData, setUserData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showVerificationModal, setShowVerificationModal] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("Admin");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const headers = headerFormat;
    const [userList, setUserList] = useState([]);

    const getUsers = () => {
        AmplifyApi.getUsers().then(response => response.json()
            .then(json => {
                if (response.ok) {
                    console.log(json);
                    setUserList(json.Users);
                } else {
                    console.log("Get Users Error:", json);
                }
            }))
            .catch(reason => {
                console.log("Get Users Error:", reason);
            });
    }
    useEffect(() => {
        if (isLogined) {
            getUsers();
        }

    }, [isLogined])

    const onAdd = () => {
        const reqBody = {
            "email": email,
            "password": password,
            "firstName": firstName,
            "lastName": lastName,
            "role": role
        }
        AmplifyApi.createUser(reqBody).then(result => result.json()
            .then(json => {
                if (json.code) console.log(json.code)
                else {
                    setShowAddModal(false);
                    // setShowVerificationModal(true);
                    getUsers();
                }
            }))
            .catch(reason => {
                console.log(reason);
            });
    }

    const onVerification = () => {
        AmplifyApi.signUpVerification({ email, verificationCode }).then(result => result.json()
            .then(json => {
                if (json.code) console.log(json.code)
                else {
                    setShowVerificationModal(false);
                    getUsers();
                    alert("Successfully added!");
                }
            }))
            .catch(reason => {
                console.log(reason);
            });
    }

    const onUpdate = () => {
        const reqBody = {
            "Username": userData.Username || "",
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "role": role
        };
        AmplifyApi.updateUser(reqBody).then(result => result.json()
            .then(json => {
                if (json.code) console.log(json.code);
                else {
                    setShowEditModal(false);
                    getUsers();
                    alert("Successfuly updated!");
                }
            }))
            .catch(reason => {
                console.log(reason);
            });
    }

    const onDelete = (user) => {
        setUserData(user);
        const reqBody = {
            "Username": user.Username || "",
        };
        AmplifyApi.deleteUser(reqBody).then(result => result.json()
            .then(json => {
                if (json.code) {
                    console.log(json.code);
                }
                else {
                    getUsers();
                    alert("Successfuly deleted!");
                }
            }))
            .catch(reason => {
                console.log(reason);
            });
    }

    const onEdit = (user) => {
        setUserData(user);
        user.Attributes.map((data) => {
            switch (data.Name) {
                case "profile": {
                    setRole(data.Value);
                    break;
                }
                case "given_name": {
                    setFirstName(data.Value);
                    break;
                }
                case "family_name": {
                    setLastName(data.Value);
                    break;
                }
                case "email": {
                    setEmail(data.Value);
                    break;
                }
            }
        })
        setShowEditModal(true);
    }

    return (
        <>
            {!isLogined ?
                <Login
                    isAdmin={true}
                    setIsLogined={setIsLogined}
                /> :
                <Container>
                    <Header>
                        <h1>Backstage Management</h1>
                        <Logout
                            isAdmin={true}
                            setIsLogined={setIsLogined}
                        />
                    </Header>
                    <Body>
                        <Section>
                            <AddBar>
                                <Button
                                    text="Add User"
                                    onClick={() => {
                                        setShowAddModal(true);
                                        setFirstName("");
                                        setLastName("");
                                        setEmail("");
                                        setPassword("");
                                        setRole("Admin");
                                    }}
                                />
                            </AddBar>
                        </Section>

                        <Section>
                            <Title>User List</Title>
                            <TableContainer>
                                <Table
                                    headers={headers}
                                    userList={userList}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                />
                            </TableContainer>
                        </Section>
                    </Body>
                    {showAddModal &&
                        <Modal
                            showModal={showAddModal}
                            setShowModal={setShowAddModal}
                            onAdd={onAdd}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            role={role}
                            setRole={setRole}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                        />
                    }
                    {showEditModal &&
                        <Modal
                            showModal={showEditModal}
                            setShowModal={setShowEditModal}
                            userData={userData}
                            onUpdate={onUpdate}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            role={role}
                            setRole={setRole}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                        />
                    }
                    {showVerificationModal &&
                        <VerificationModal
                            showModal={showVerificationModal}
                            setShowModal={setShowVerificationModal}
                            onVerification={onVerification}
                            setVerificationCode={setVerificationCode}
                        />
                    }
                </Container>
            }
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--green-primary);
    margin: 0;
    padding: 17px 30px;;
    box-sizing: border-box;
    color: #fff;
    height: 90px;
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 90px);
    box-sizing: border-box;
    padding: 30px;
    gap: 10px;
`
const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;
`
const Title = styled.div`
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    padding: 0 20px;
    background-color: var(--green-primary);
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
`
const AddBar = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: end;
`
const TableContainer = styled.div`
    width: 100%;
    max-height: calc(100vh - 90px - 30px - 150px - 150px);
    overflow: auto;
    ::-webkit-scrollbar{
        width: 8px;
        height: 4px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
    }
    ::-webkit-scrollbar-thumb{
        border-radius: 4px;
        background: #c7c4c4;
    }
`

export default Admin;
