import { apiGet, apiPost, apiUpdate, apiDelete } from "./api-template";

export const signIn = ({ email, password, isAdminLogin }) => {
    return apiPost("/sign-in", { email, password, isAdminLogin });
};

export const signUp = (req) => {
    return apiPost("/sign-up", req);
};

export const signOut = () => {
    return apiGet("/sign-out");
};

export const getUsers = () => {
    return apiGet("/list-users");
};

export const createUser = (req) => {
    return apiPost("/create-user", req);
};

export const signUpVerification = ({ email, verificationCode }) => {
    return apiPost("/verification", { email, verificationCode });
};

export const updateUser = (req) => {
    return apiUpdate("/update-user", req);
};

export const deleteUser = (req) => {
    return apiPost("/delete-user", req);
};