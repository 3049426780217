import { Fragment, useEffect, useState, useRef } from "react";
import styled from "styled-components"
import { useSelector } from "react-redux";

import { KeyGrops, AllSearchTypes } from "../../utils/consts";
import { CircleButton } from "../Button";
import ToolTip from "../ToolTip";
import { desktop, tabletSmall } from "../../styles";

export default (props) => {
    const searchType = useSelector(state => state.searchType);
    const { cardData, cardIndex } = props;
    const [infoIndex, setInfoIndex] = useState(-1);

    const [basicInfo, setBasicInfo] = useState([]);
    useEffect(() => {
        switch (searchType) {
            case AllSearchTypes[0]: {
                setBasicInfo(KeyGrops.Announcement);
                return;
            }
            case AllSearchTypes[1]: {
                setBasicInfo(KeyGrops.Company);
                return;
            }
            case AllSearchTypes[2]: {
                setBasicInfo(KeyGrops.Director);
                return;
            }
        }
    }, [searchType]);

    const details = [
        {
            displayName: "C",
            tooltip: "Company Info",
            enabled: true
        },
        {
            displayName: "S",
            tooltip: "Share Info",
            enabled: true
        },
        {
            displayName: "I",
            tooltip: "Indices Info",
            enabled: cardData.has_indices
        },
        {
            displayName: "D",
            tooltip: "Dividend Info",
            enabled: cardData.has_dividends
        },
        {
            displayName: "R",
            tooltip: "REIT Info",
            enabled: cardData.has_reit
        },
        {
            displayName: "L",
            tooltip: "LIC Info",
            enabled: cardData.has_lics
        }
    ];

    const selector = useRef(null);
    const onClickOutside = (e) => {
        if (!selector || !selector?.current) return;

        if (!selector.current.contains(e.target)) {
            setInfoIndex(-1);
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        }
    });

    return (
        <Container>
            <BasicInfo>
                {basicInfo.map((item, index) =>
                    <Row key={index}>
                        <Key>{item.displayName}: </Key>
                        <Value>
                            {item.dataField === "url" ?
                                <a href={cardData[item.dataField]} target="_blank">
                                    View Announcement
                                </a> :
                                <>
                                    {item.dataField === "title" ?
                                        <ToolTip
                                            width={300}
                                            text={cardData[item.dataField]}
                                        >
                                            {cardData[item.dataField].length > 30 ?
                                                cardData[item.dataField].slice(0, 30) + "..."
                                                :
                                                cardData[item.dataField]}
                                        </ToolTip>
                                        :
                                        <>
                                            {cardData[item.dataField]}
                                        </>
                                    }
                                </>
                            }
                        </Value>
                    </Row>
                )}
            </BasicInfo>

            <ThreeDots
                img="img/three-dots.svg"
                bg="var(--shade-4)"
                size="30px"
                onClick={() => console.log("you clicked!")}
            />

            <DetailButtonBar>
                {
                    details.map((detail, index) => {
                        if (detail.enabled) {
                            return (
                                <DetailButton
                                    bg={infoIndex === index ? "var(--purple-primary)" : "var(--green-secondary)"}
                                    color={infoIndex === index ? "var(--green-secondary)" : "var(--purple-primary)"}
                                    key={index}
                                    onClick={() => setInfoIndex(index)}
                                >
                                    <ToolTip text={detail.tooltip}>
                                        {detail.displayName}
                                    </ToolTip>
                                </DetailButton>
                            )
                        }
                        else {
                            return (
                                <DetailButton
                                    bg="var(--shade-4)"
                                    color="var(--shade-3)"
                                    key={index}
                                >
                                    <ToolTip text={detail.tooltip}>
                                        {detail.displayName}
                                    </ToolTip>
                                </DetailButton>
                            )
                        }
                    })
                }
            </DetailButtonBar>
            {!(infoIndex === -1) &&
                <Info
                    ref={selector}
                    cardIndex={cardIndex}
                >
                    <Title>{details[infoIndex].tooltip}</Title>
                    {KeyGrops.Default[infoIndex].map((item, index) =>
                        <Row key={index}>
                            <Key>{item.displayName}: </Key>
                            <Value>
                                {item.dataField === "directors" ?
                                    <>
                                        {cardData[item.dataField].map(content => (
                                            <Fragment key={content}>
                                                {content}
                                                <br />
                                            </Fragment>
                                        ))}
                                    </> :
                                    <>
                                        {item.dataField === "indices" ?
                                            <>
                                                {cardData[item.dataField].map((content, index) => (
                                                    <Fragment key={content}>
                                                        index{index + 1}: {content}
                                                        <br />
                                                    </Fragment>
                                                ))}
                                            </> :
                                            <>
                                                {cardData[item.dataField]}
                                            </>
                                        }
                                    </>
                                }

                            </Value>
                        </Row>
                    )}
                </Info>
            }
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc((100vw - 350px - 90px - 50px)/2);
    ${desktop} {
        width: calc(100vw - 350px - 90px);
    }
    ${tabletSmall} {
        width: calc(100vw - 40px);
    }
    box-sizing: border-box;
    font-size: 14px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
    padding: 24px;
    border-radius: 24px;
    background-color: var(--shade-7);
    gap: 10px;
    position: relative;
`
const BasicInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
const ThreeDots = styled(CircleButton)`
    position: absolute;
    top: 24px;
    right: 24px;
`
const DetailButtonBar = styled.div`
    display: flex;
    gap: 20px;
`
const DetailButton = styled.div`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: ${({ bg }) => bg};
    color: ${({ color }) => color};
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Row = styled.div`
    display: flex;
    gap: 10px;
`
const Key = styled.span`
    color: var(--shade-2);
    white-space: nowrap;
`
const Value = styled.b`
    color: var(--shade-1);
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
`
const Info = styled(Container)`
    width: calc(100vw - 350px - 90px - 8px);
    ${tabletSmall} {
        width: calc(100vw - 40px - 8px);
    }
    gap: 5px;
    box-shadow: 0px 0px 50px rgba(0,0,0,0.3);
    position: absolute;
    top: calc(100% + 10px);
    left: ${({ cardIndex }) => cardIndex % 2 === 0 ? "0px" : null};
    right: ${({ cardIndex }) => cardIndex % 2 === 0 ? null : "0px"};
    z-index: 98;
`
const Title = styled.span`
    font-size: 16px;
    color: var(--purple-primary);
    font-weight: 600;
`