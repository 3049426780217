import React from "react";
import styled from "styled-components";

export default ({ children, text, width }) => (
    <ToolTip>
        {children}
        <ToolTipText width={width}>
            {text}
        </ToolTipText>
    </ToolTip>
);

const ToolTipText = styled.span`
    visibility: hidden;
    width: ${({ width }) => width ? width + "px" : "120px"};
    background-color: var(--shade-1);
    color: var(--shade-7);
    white-space: normal;
    overflow: visible;
    font-family: inter;
    font-weight: 400;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 10;
    bottom: 110%;
    left: 50%;
    margin-left: ${({ width }) => width ? (-1) * width / 2 + "px" : "-60px"};
    :after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: var(--shade-1) transparent transparent transparent;
    }
`;
const ToolTip = styled.div`
    position: relative;
    display: inline-block;
    &:hover {
        ${ToolTipText} {
            visibility: visible;
        }
    }
`;