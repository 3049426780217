export const DataType = {
    VALUE_RANGE: "ValueRange",
    DATE_RANGE: "DateRange",
    SELECT: "Select",
    BOOLEAN: "Boolean"
}

const DbKey = {
    ticker: "ticker",
    name: "name",
    principal_activities: "principal_activities",
    gics_industry: "gics_industry",
    gics_sector: "gics_sector",
    listing_date: "listing_date",
    delisting_date: "delisting_date",
    website: "website",
    mailing_address: "mailing_address",
    phone_number: "phone_number",
    fax_number: "fax_number",
    registry_name: "registry_name",
    registry_phone_number: "registry_phone_number",
    foreign_exempt: "foreign_exempt",
    products: "products",
    primary_share_type: "primary_share.type",
    primary_share_open_price: "primary_share.open_price",
    primary_share_last_price: "primary_share.last_price",
    primary_share_bid_price: "primary_share.bid_price",
    primary_share_offer_price: "primary_share.offer_price",
    primary_share_last_trade_date: "primary_share.last_trade_date",
    primary_share_day_high_price: "primary_share.day_high_price",
    primary_share_day_low_price: "primary_share.day_low_price",
    primary_share_day_change_price: "primary_share.day_change_price",
    primary_share_day_change_percent: "primary_share.day_change_percent",
    primary_share_year_high_price: "primary_share.year_high_price",
    primary_share_year_high_date: "primary_share.year_high_date",
    primary_share_year_low_price: "primary_share.year_low_price",
    primary_share_year_low_date: "primary_share.year_low_date",
    primary_share_year_open_price: "primary_share.year_open_price",
    primary_share_year_change_price: "primary_share.year_change_price",
    primary_share_year_change_percent: "primary_share.year_change_percent",
    primary_share_day_volume: "primary_share.average_daily_volume",
    primary_share_pe: "primary_share.pe",
    primary_share_eps: "primary_share.eps",
    primary_share_securities_outstanding: "primary_share.securities_outstanding",
    primary_share_market_cap: "primary_share.market_cap",
    primary_share_is_suspended: "primary_share.is_suspended",
    announcements_url: "url",
    announcements_title: "title",
    announcements_document_date: "document_date",
    announcements_release_date: "release_date",
    line_length: "nums_of_lines",
    market_sensitive: "market_sensitive",
    has_dividends: "has_dividends",
    last_dividend_type: "last_dividend.type",
    last_dividend_created_date: "last_dividend.created_date",
    last_dividend_ex_date: "last_dividend.ex_date",
    last_dividend_payment_date: "last_dividend.payment_date",
    last_dividend_record_date: "last_dividend.record_date",
    last_dividend_books_date: "last_dividend.books_date",
    last_dividend_amount_aud: "last_dividend.amount_aud",
    last_dividend_franked_percent: "last_dividend.franked_percent",
    last_dividend_comments: "last_dividend.comments",
    has_reit: 'has_reit',
    has_lics: 'has_lics',
    reit_investment_theme: 'reit_investment_theme',
    reit_exposure: "reit_exposure",
    reit_type: 'reit_type',
    reit_listing_date: 'reit_listing_date',
    lics_investment_theme: 'lics_investment_theme',
    lics_exposure: 'lics_exposure',
    lics_type: 'lics_type',
    lics_benchmark: 'lics_benchmark',
    lics_mer_percent: 'lics_mer_percent',
    lics_listing_date: 'lics_listing_date',
    lics_outperf_fee: 'lics_outperf_fee',
    has_indices: 'has_indices',
    sub_industry: 'sub_industry',
    industry_group: 'industry_group',
}

//Filter
export const AnnouncementOptions = [
    {
        dataField: DbKey.primary_share_market_cap,
        displayName: "Market Cap",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100000,
        value: "",
    },
    {
        dataField: DbKey.gics_industry,
        displayName: "Industry",
        dataType: DataType.SELECT,
        selects: ["Electrical Equipment",
            "Biotechnology",
            "Metals & Mining",
            "Food Products",
            "Aerospace & Defense",
            "Health Care Technology",
            "Oil, Gas & Consumable Fuels",
            "Software",
            "Machinery",
            "Commercial Services & Supplies",
            "Diversified Consumer Services",
            "Semiconductors & Semiconductor Equipment",
            "Diversified Telecommunication Services",
            "Diversified Financial Services",
            "Road & Rail",
            "Chemicals",
            "Thrifts & Mortgage Finance",
            "Construction Materials",
            "Capital Markets",
            "Equity Real Estate Investment Trusts (REITs)",
            "Auto Components",
            "Internet & Direct Marketing Retail",
            "Pharmaceuticals",
            "Health Care Providers & Services",
            "Real Estate Management & Development",
            "Interactive Media & Services",
            "Electronic Equipment, Instruments & Components",
            "Specialty Retail",
            "Life Sciences Tools & Services",
            "Construction & Engineering",
            "Professional Services",
            "Hotels, Restaurants & Leisure",
            "Multi-Utilities",
            "Health Care Equipment & Supplies",
            "Transportation Infrastructure",
            "Airlines",
            "Household Durables",
            "Containers & Packaging",
            "Personal Products",
            "Banks",
            "Gas Utilities",
            "IT Services",
            "Media",
            "Automobiles",
            "Textiles, Apparel & Luxury Goods",
            "Insurance",
            "Beverages",
            "Consumer Finance",
            "Distributors",
            "Entertainment",
            "Communications Equipment",
            "Technology Hardware, Storage & Peripherals",
            "Paper & Forest Products",
            "Independent Power and Renewable Electricity Producers",
            "Electric Utilities",
            "Food & Staples Retailing",
            "Air Freight & Logistics",
            "Leisure Products",
            "Water Utilities",
            "Building Products",
            "Wireless Telecommunication Services",
            "Multiline Retail",
            "Energy Equipment & Services",
            "Household Products",
            "Mortgage Real Estate Investment Trusts (REITs)",
            "Industrial Conglomerates"],
        value: "",
    },
    {
        dataField: DbKey.gics_sector,
        displayName: "Sector",
        dataType: DataType.SELECT,
        selects: ["Communication Services", "Consumer Discretionary", "Consumer Staples", "Energy", "Financials", "Health Care", "Industrials", "Information Technology", "Materials", "Real Estate", "Utilities"],
        value: "",
    },
    {
        dataField: DbKey.industry_group,
        displayName: "Industry Group",
        dataType: DataType.SELECT,
        selects: ["Capital Goods",
            "Pharmaceuticals, Biotechnology & Life Sciences",
            "Materials",
            "Food, Beverage & Tobacco",
            "Health Care Equipment & Services",
            "Energy",
            "Software & Services",
            "Commercial & Professional Services",
            "Consumer Services",
            "Semiconductors & Semiconductor Equipment",
            "Telecommunication Services",
            "Diversified Financials",
            "Transportation",
            "Banks",
            "Real Estate",
            "Automobiles & Components",
            "Retailing",
            "Media & Entertainment",
            "Technology Hardware & Equipment",
            "Utilities",
            "Consumer Durables & Apparel",
            "Household & Personal Products",
            "Insurance",
            "Food & Staples Retailing"],
        value: "",
    },
    {
        dataField: DbKey.sub_industry,
        displayName: "Sub Industry",
        dataType: DataType.SELECT,
        selects: ["Electrical Components & Equipment",
            "Biotechnology",
            "Diversified Metals & Mining",
            "Agricultural Products",
            "Aerospace & Defense",
            "Health Care Technology",
            "Gold",
            "Oil & Gas Storage & Transportation",
            "Application Software",
            "Industrial Machinery",
            "Commercial Printing",
            "Education Services",
            "Semiconductors",
            "Integrated Telecommunication Services",
            "Oil & Gas Exploration & Production",
            "Other Diversified Financial Services",
            "Coal & Consumable Fuels",
            "Steel",
            "Trucking",
            "Packaged Foods & Meats",
            "Commodity Chemicals",
            "Thrifts & Mortgage Finance",
            "Alternative Carriers",
            "Construction Materials",
            "Financial Exchanges & Data",
            "Diversified REITs",
            "Auto Parts & Equipment",
            "Aluminum",
            "Internet & Direct Marketing Retail",
            "Pharmaceuticals",
            "Health Care Services",
            "Diversified Capital Markets",
            "Real Estate Services",
            "Interactive Media & Services",
            "Electronic Manufacturing Services",
            "Homefurnishing Retail",
            "Life Sciences Tools & Services",
            "Construction & Engineering",
            "Asset Management & Custody Banks",
            "Environmental & Facilities Services",
            "Multi-Sector Holdings",
            "Research & Consulting Services",
            "Precious Metals & Minerals",
            "Casinos & Gaming",
            "Multi-Utilities",
            "Health Care Equipment",
            "Systems Software",
            "Airport Services",
            "Specialized Finance",
            "Diversified Support Services",
            "Copper",
            "Airlines",
            "Diversified Chemicals",
            "Consumer Electronics",
            "Oil & Gas Refining & Marketing",
            "Leisure Facilities",
            "Hotels, Resorts & Cruise Lines",
            "Highways & Railtracks",
            "Paper Packaging",
            "Technology Distributors",
            "Personal Products",
            "Construction Machinery & Heavy Trucks",
            "Health Care Supplies",
            "Specialty Chemicals",
            "Diversified Banks",
            "Office REITs",
            "Gas Utilities",
            "Automotive Retail",
            "Human Resource & Employment Services",
            "IT Consulting & Other Services",
            "Specialized REITs",
            "Publishing",
            "Automobile Manufacturers",
            "Apparel, Accessories & Luxury Goods",
            "Insurance Brokers",
            "Specialty Stores",
            "Electronic Equipment & Instruments",
            "Distillers & Vintners",
            "Homebuilding",
            "Apparel Retail",
            "Real Estate Development",
            "Multi-line Insurance",
            "Railroads",
            "Consumer Finance",
            "Distributors",
            "Regional Banks",
            "Movies & Entertainment",
            "Communications Equipment",
            "Electronic Components",
            "Technology Hardware, Storage & Peripherals",
            "Brewers",
            "Investment Banking & Brokerage",
            "Fertilizers & Agricultural Chemicals",
            "Semiconductor Equipment",
            "Home Improvement Retail",
            "Household Appliances",
            "Forest Products",
            "Retail REITs",
            "Data Processing & Outsourced Services",
            "Renewable Electricity",
            "Electric Utilities",
            "Industrial REITs",
            "Restaurants",
            "Food Distributors",
            "Air Freight & Logistics",
            "Advertising",
            "Leisure Products",
            "Water Utilities",
            "Marine Ports & Services",
            "Internet Services & Infrastructure",
            "Real Estate Operating Companies",
            "Health Care Distributors",
            "Health Care Facilities",
            "Interactive Home Entertainment",
            "Building Products",
            "Soft Drinks",
            "Independent Power Producers & Energy Traders",
            "Home Furnishings",
            "Life & Health Insurance",
            "Diversified Real Estate Activities",
            "Specialized Consumer Services",
            "Broadcasting",
            "Wireless Telecommunication Services",
            "General Merchandise Stores",
            "Property & Casualty Insurance",
            "Security & Alarm Services",
            "Residential REITs",
            "Office Services & Supplies",
            "Computer & Electronics Retail",
            "Oil & Gas Equipment & Services",
            "Footwear",
            "Paper Products",
            "Heavy Electrical Equipment",
            "Department Stores",
            "Metal & Glass Containers",
            "Household Products",
            "Mortgage REITs",
            "Agricultural & Farm Machinery",
            "Oil & Gas Drilling",
            "Cable & Satellite",
            "Industrial Conglomerates",
            "Silver",
            "Motorcycle Manufacturers",
            "Food Retail",],
        value: "",
    },
    {
        dataField: DbKey.primary_share_year_open_price,
        displayName: "Year Stock Started Trading",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.market_sensitive,
        displayName: "Market Sensitive",
        dataType: DataType.BOOLEAN,
        value: true,
    },
    {
        dataField: DbKey.has_dividends,
        displayName: "Has Dividends",
        dataType: DataType.BOOLEAN,
        value: true,
    },
    {
        dataField: DbKey.last_dividend_amount_aud,
        displayName: "Dividend Amount",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 1000,
        value: 0,
    },
    {
        dataField: DbKey.last_dividend_record_date,
        displayName: "Dividend Date",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.announcements_release_date,
        displayName: "Date Announcement Was Published",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.primary_share_pe,
        displayName: "P/E",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100,
        value: 0
    },
    {
        dataField: DbKey.primary_share_eps,
        displayName: "EPS",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100,
        value: 0
    },
    {
        dataField: DbKey.primary_share_average_daily_volume,
        displayName: "Average Daily Volume",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 1000000,
        value: 0
    },
    {
        dataField: DbKey.primary_share_last_price,
        displayName: "Last Price",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100,
        value: 0
    },
    {
        dataField: DbKey.primary_share_last_trade_date,
        displayName: "Last Trade Date",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.listing_date,
        displayName: "Listing Date",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.foreign_exempt,
        displayName: "Foreign Exempt",
        dataType: DataType.BOOLEAN,
        value: true
    },
    {
        dataField: DbKey.has_reit,
        displayName: "Has REITs",
        dataType: DataType.BOOLEAN,
        value: true
    },
    {
        dataField: DbKey.has_lics,
        displayName: "Has LICs",
        dataType: DataType.BOOLEAN,
        value: true
    },
    {
        dataField: DbKey.primary_share_type,
        displayName: "Share Type",
        dataType: DataType.SELECT,
        selects: ["Ordinary Fully Paid",]
    },
    {
        dataField: DbKey.primary_share_securities_outstanding,
        displayName: "Securities Outstanding",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 999999999,
        value: 0
    }
];

export const CompanyOptions = [
    {
        dataField: DbKey.primary_share_market_cap,
        displayName: "Market Cap",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100000,
        value: "",
    },
    {
        dataField: DbKey.gics_industry,
        displayName: "Industry",
        dataType: DataType.SELECT,
        selects: ["Electrical Equipment",
            "Biotechnology",
            "Metals & Mining",
            "Food Products",
            "Aerospace & Defense",
            "Health Care Technology",
            "Oil, Gas & Consumable Fuels",
            "Software",
            "Machinery",
            "Commercial Services & Supplies",
            "Diversified Consumer Services",
            "Semiconductors & Semiconductor Equipment",
            "Diversified Telecommunication Services",
            "Diversified Financial Services",
            "Road & Rail",
            "Chemicals",
            "Thrifts & Mortgage Finance",
            "Construction Materials",
            "Capital Markets",
            "Equity Real Estate Investment Trusts (REITs)",
            "Auto Components",
            "Internet & Direct Marketing Retail",
            "Pharmaceuticals",
            "Health Care Providers & Services",
            "Real Estate Management & Development",
            "Interactive Media & Services",
            "Electronic Equipment, Instruments & Components",
            "Specialty Retail",
            "Life Sciences Tools & Services",
            "Construction & Engineering",
            "Professional Services",
            "Hotels, Restaurants & Leisure",
            "Multi-Utilities",
            "Health Care Equipment & Supplies",
            "Transportation Infrastructure",
            "Airlines",
            "Household Durables",
            "Containers & Packaging",
            "Personal Products",
            "Banks",
            "Gas Utilities",
            "IT Services",
            "Media",
            "Automobiles",
            "Textiles, Apparel & Luxury Goods",
            "Insurance",
            "Beverages",
            "Consumer Finance",
            "Distributors",
            "Entertainment",
            "Communications Equipment",
            "Technology Hardware, Storage & Peripherals",
            "Paper & Forest Products",
            "Independent Power and Renewable Electricity Producers",
            "Electric Utilities",
            "Food & Staples Retailing",
            "Air Freight & Logistics",
            "Leisure Products",
            "Water Utilities",
            "Building Products",
            "Wireless Telecommunication Services",
            "Multiline Retail",
            "Energy Equipment & Services",
            "Household Products",
            "Mortgage Real Estate Investment Trusts (REITs)",
            "Industrial Conglomerates"],
        value: "",
    },
    {
        dataField: DbKey.gics_sector,
        displayName: "Sector",
        dataType: DataType.SELECT,
        selects: ["Communication Services", "Consumer Discretionary", "Consumer Staples", "Energy", "Financials", "Health Care", "Industrials", "Information Technology", "Materials", "Real Estate", "Utilities"],
        value: "",
    },
    {
        dataField: DbKey.industry_group,
        displayName: "Industry Group",
        dataType: DataType.SELECT,
        selects: ["Capital Goods",
            "Pharmaceuticals, Biotechnology & Life Sciences",
            "Materials",
            "Food, Beverage & Tobacco",
            "Health Care Equipment & Services",
            "Energy",
            "Software & Services",
            "Commercial & Professional Services",
            "Consumer Services",
            "Semiconductors & Semiconductor Equipment",
            "Telecommunication Services",
            "Diversified Financials",
            "Transportation",
            "Banks",
            "Real Estate",
            "Automobiles & Components",
            "Retailing",
            "Media & Entertainment",
            "Technology Hardware & Equipment",
            "Utilities",
            "Consumer Durables & Apparel",
            "Household & Personal Products",
            "Insurance",
            "Food & Staples Retailing"],
        value: "",
    },
    {
        dataField: DbKey.sub_industry,
        displayName: "Sub Industry",
        dataType: DataType.SELECT,
        selects: ["Electrical Components & Equipment",
            "Biotechnology",
            "Diversified Metals & Mining",
            "Agricultural Products",
            "Aerospace & Defense",
            "Health Care Technology",
            "Gold",
            "Oil & Gas Storage & Transportation",
            "Application Software",
            "Industrial Machinery",
            "Commercial Printing",
            "Education Services",
            "Semiconductors",
            "Integrated Telecommunication Services",
            "Oil & Gas Exploration & Production",
            "Other Diversified Financial Services",
            "Coal & Consumable Fuels",
            "Steel",
            "Trucking",
            "Packaged Foods & Meats",
            "Commodity Chemicals",
            "Thrifts & Mortgage Finance",
            "Alternative Carriers",
            "Construction Materials",
            "Financial Exchanges & Data",
            "Diversified REITs",
            "Auto Parts & Equipment",
            "Aluminum",
            "Internet & Direct Marketing Retail",
            "Pharmaceuticals",
            "Health Care Services",
            "Diversified Capital Markets",
            "Real Estate Services",
            "Interactive Media & Services",
            "Electronic Manufacturing Services",
            "Homefurnishing Retail",
            "Life Sciences Tools & Services",
            "Construction & Engineering",
            "Asset Management & Custody Banks",
            "Environmental & Facilities Services",
            "Multi-Sector Holdings",
            "Research & Consulting Services",
            "Precious Metals & Minerals",
            "Casinos & Gaming",
            "Multi-Utilities",
            "Health Care Equipment",
            "Systems Software",
            "Airport Services",
            "Specialized Finance",
            "Diversified Support Services",
            "Copper",
            "Airlines",
            "Diversified Chemicals",
            "Consumer Electronics",
            "Oil & Gas Refining & Marketing",
            "Leisure Facilities",
            "Hotels, Resorts & Cruise Lines",
            "Highways & Railtracks",
            "Paper Packaging",
            "Technology Distributors",
            "Personal Products",
            "Construction Machinery & Heavy Trucks",
            "Health Care Supplies",
            "Specialty Chemicals",
            "Diversified Banks",
            "Office REITs",
            "Gas Utilities",
            "Automotive Retail",
            "Human Resource & Employment Services",
            "IT Consulting & Other Services",
            "Specialized REITs",
            "Publishing",
            "Automobile Manufacturers",
            "Apparel, Accessories & Luxury Goods",
            "Insurance Brokers",
            "Specialty Stores",
            "Electronic Equipment & Instruments",
            "Distillers & Vintners",
            "Homebuilding",
            "Apparel Retail",
            "Real Estate Development",
            "Multi-line Insurance",
            "Railroads",
            "Consumer Finance",
            "Distributors",
            "Regional Banks",
            "Movies & Entertainment",
            "Communications Equipment",
            "Electronic Components",
            "Technology Hardware, Storage & Peripherals",
            "Brewers",
            "Investment Banking & Brokerage",
            "Fertilizers & Agricultural Chemicals",
            "Semiconductor Equipment",
            "Home Improvement Retail",
            "Household Appliances",
            "Forest Products",
            "Retail REITs",
            "Data Processing & Outsourced Services",
            "Renewable Electricity",
            "Electric Utilities",
            "Industrial REITs",
            "Restaurants",
            "Food Distributors",
            "Air Freight & Logistics",
            "Advertising",
            "Leisure Products",
            "Water Utilities",
            "Marine Ports & Services",
            "Internet Services & Infrastructure",
            "Real Estate Operating Companies",
            "Health Care Distributors",
            "Health Care Facilities",
            "Interactive Home Entertainment",
            "Building Products",
            "Soft Drinks",
            "Independent Power Producers & Energy Traders",
            "Home Furnishings",
            "Life & Health Insurance",
            "Diversified Real Estate Activities",
            "Specialized Consumer Services",
            "Broadcasting",
            "Wireless Telecommunication Services",
            "General Merchandise Stores",
            "Property & Casualty Insurance",
            "Security & Alarm Services",
            "Residential REITs",
            "Office Services & Supplies",
            "Computer & Electronics Retail",
            "Oil & Gas Equipment & Services",
            "Footwear",
            "Paper Products",
            "Heavy Electrical Equipment",
            "Department Stores",
            "Metal & Glass Containers",
            "Household Products",
            "Mortgage REITs",
            "Agricultural & Farm Machinery",
            "Oil & Gas Drilling",
            "Cable & Satellite",
            "Industrial Conglomerates",
            "Silver",
            "Motorcycle Manufacturers",
            "Food Retail",],
        value: "",
    },
    {
        dataField: DbKey.primary_share_year_open_price,
        displayName: "Year Stock Started Trading",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.has_dividends,
        displayName: "Has Dividends",
        dataType: DataType.BOOLEAN,
        value: true,
    },
    {
        dataField: DbKey.last_dividend_amount_aud,
        displayName: "Dividend Amount",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 1000,
        value: 0,
    },
    {
        dataField: DbKey.last_dividend_record_date,
        displayName: "Dividend Date",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.primary_share_pe,
        displayName: "P/E",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100,
        value: 0
    },
    {
        dataField: DbKey.primary_share_eps,
        displayName: "EPS",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100,
        value: 0
    },
    {
        dataField: DbKey.primary_share_average_daily_volume,
        displayName: "Average Daily Volume",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 1000000,
        value: 0
    },
    {
        dataField: DbKey.primary_share_last_price,
        displayName: "Last Price",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 100,
        value: 0
    },
    {
        dataField: DbKey.primary_share_last_trade_date,
        displayName: "Last Trade Date",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.listing_date,
        displayName: "Listing Date",
        dataType: DataType.DATE_RANGE,
        rangeStart: "1900-01-01T13:00:00.000Z",
        rangeEnd: new Date(Date.now()).toDateString().toString(),
        value: "",
    },
    {
        dataField: DbKey.foreign_exempt,
        displayName: "Foreign Exempt",
        dataType: DataType.BOOLEAN,
        value: true
    },
    {
        dataField: DbKey.has_reit,
        displayName: "Has REITs",
        dataType: DataType.BOOLEAN,
        value: true
    },
    {
        dataField: DbKey.has_lics,
        displayName: "Has LICs",
        dataType: DataType.BOOLEAN,
        value: true
    },
    {
        dataField: DbKey.primary_share_type,
        displayName: "Share Type",
        dataType: DataType.SELECT,
        selects: ["Ordinary Fully Paid",]
    },
    {
        dataField: DbKey.primary_share_securities_outstanding,
        displayName: "Securities Outstanding",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 999999999,
        value: 0
    }
];

export const DirectorOptions = [
    {
        dataField: "directors.age",
        displayName: "Age",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 200,
        value: "",
    },
    {
        dataField: "directors.year_born",
        displayName: "Year Born",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 1900,
        rangeEnd: 2100,
        value: "",
    },
    {
        dataField: "directors.total_pay",
        displayName: "Remuneration",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 0,
        rangeEnd: 1000000000,
        value: "",
    },
    {
        dataField: "directors.fiscal_year",
        displayName: "Fiscal Year",
        dataType: DataType.VALUE_RANGE,
        rangeStart: 1900,
        rangeEnd: 2100,
        value: "",
    },
];

export const AllOptionsGroup = [
    AnnouncementOptions,
    CompanyOptions,
    DirectorOptions
];

//Sort
export const AnnouncementSorts = [
    {
        dataField: "none",
        displayName: "None",
    },
    {
        dataField: DbKey.primary_share_market_cap,
        displayName: "Market Cap",
    },
    {
        dataField: DbKey.last_dividend_amount_aud,
        displayName: "Dividend Amount",
    },
    {
        dataField: DbKey.last_dividend_record_date,
        displayName: "Dividend Date",
    },
    {
        dataField: DbKey.announcements_release_date,
        displayName: "Date Announcement Was Published",
    },
    {
        dataField: DbKey.primary_share_pe,
        displayName: "P/E",
    },
    {
        dataField: DbKey.primary_share_last_price,
        displayName: "Last Price",
    },
    {
        dataField: DbKey.listing_date,
        displayName: "Listing Date",
    },
    {
        dataField: DbKey.primary_share_securities_outstanding,
        displayName: "Securities Outstanding",
    },
];

export const CompanySorts = [
    {
        dataField: "none",
        displayName: "None",
    },
    {
        dataField: DbKey.primary_share_market_cap,
        displayName: "Market Cap",
    },
    {
        dataField: DbKey.last_dividend_amount_aud,
        displayName: "Dividend Amount",
    },
    {
        dataField: DbKey.last_dividend_record_date,
        displayName: "Dividend Date",
    },
    {
        dataField: DbKey.primary_share_pe,
        displayName: "P/E",
    },
    {
        dataField: DbKey.primary_share_last_price,
        displayName: "Last Price",
    },
    {
        dataField: DbKey.listing_date,
        displayName: "Listing Date",
    },
    {
        dataField: DbKey.primary_share_securities_outstanding,
        displayName: "Securities Outstanding",
    },
];

export const DirectorSorts = [
    {
        dataField: "none",
        displayName: "None",
    },
    {
        dataField: "director.age",
        displayName: "Age",
    },
    {
        dataField: "director.year_born",
        displayName: "Year Born",
    },
    {
        dataField: "director.total_pay",
        displayName: "Remuneration",
    },
    {
        dataField: "director.fiscal_year",
        displayName: "Fiscal Year",
    },
];

export const AllSortsGroup = [
    AnnouncementSorts,
    CompanySorts,
    DirectorSorts
];

//Sort Order
export const AllSortOrders = [
    "Ascending",
    "Descending"
];

export const headerFormat = [
    {
        dataField: "given_name",
        displayName: "First Name"
    },
    {
        dataField: "family_name",
        displayName: "Last Name"
    },
    {
        dataField: "profile",
        displayName: "Role"
    },
    {
        dataField: "email",
        displayName: "Email"
    },
    {
        dataField: "edit",
        displayName: "Edit / Delete"
    }
];

//Result Card
export const KeyGrops = {
    Default: [
        [
            {
                dataField: "gics_industry",
                displayName: "Industry"
            },
            {
                dataField: "sub_industry",
                displayName: "Sub Industry"
            },
            {
                dataField: "gics_sector",
                displayName: "Sector"
            },
            {
                dataField: "industry_group",
                displayName: "Industry Group"
            },
            {
                dataField: "directors",
                displayName: "Directors"
            },
            {
                dataField: "market_cap",
                displayName: "Market Cap"
            },
            {
                dataField: "listing_date",
                displayName: "Listing Date"
            },
            {
                dataField: "website",
                displayName: "Website"
            },
            {
                dataField: "delisting_date",
                displayName: "Delisting Date"
            },
            {
                dataField: "has_reit",
                displayName: "Has REITs"
            },
            {
                dataField: "has_lics",
                displayName: "Has LICs"
            },
            {
                dataField: "principal_activities",
                displayName: "Principal Activities"
            },
        ],
        [
            {
                dataField: "type",
                displayName: "Share Type"
            },
            {
                dataField: "last_price",
                displayName: "Last Price"
            },
            {
                dataField: "open_price",
                displayName: "Open Price"
            },
            {
                dataField: "bid_price",
                displayName: "Bid Price"
            },
            {
                dataField: "offer_price",
                displayName: "Offer Price"
            },
            {
                dataField: "last_trade_date",
                displayName: "Last Trade Date"
            },
            {
                dataField: "day_high_price",
                displayName: "Day High Price"
            },
            {
                dataField: "day_low_price",
                displayName: "Day Low Price"
            },
            {
                dataField: "day_change_price",
                displayName: "Day Change Price"
            },
            {
                dataField: "day_change_percent",
                displayName: "Day Change Percent"
            },
            {
                dataField: "prev_day_close_price",
                displayName: "Previous Day Close Price"
            },
            {
                dataField: "prev_day_change_percent",
                displayName: "Previous Day Change Percent"
            },
            {
                dataField: "year_high_price",
                displayName: "Year High Price"
            },
            {
                dataField: "year_high_date",
                displayName: "Year High Price Date"
            },
            {
                dataField: "year_low_price",
                displayName: "Year Low Price"
            },
            {
                dataField: "year_low_date",
                displayName: "Year Low Price Date"
            },
            {
                dataField: "day_volume",
                displayName: "Average Daily Volume"
            },
            {
                dataField: "pe",
                displayName: "PE"
            },
            {
                dataField: "eps",
                displayName: "EPS"
            },
            {
                dataField: "securities_outstanding",
                displayName: "Securities Outstanding"
            },
            {
                dataField: "foreign_exempt",
                displayName: "Foreign Exempt"
            },


        ],
        [
            {
                dataField: "indices",
                displayName: "Indices"
            },
        ],
        [
            {
                dataField: "last_dividend_type",
                displayName: "Dividend Type"
            },
            {
                dataField: "last_dividend_created_date",
                displayName: "Creation Date"
            },
            {
                dataField: "last_dividend_ex_date",
                displayName: "Ex Date"
            },
            {
                dataField: "last_dividend_payment_date",
                displayName: "Payment Date"
            },
            {
                dataField: "last_dividend_record_date",
                displayName: "Record Date"
            },
            {
                dataField: "last_dividend_books_date",
                displayName: "Close Books Date"
            },
            {
                dataField: "last_dividend_amount_aud",
                displayName: "Amount(AUD)"
            },
            {
                dataField: "last_dividend_franked_percent",
                displayName: "Franked Percent"
            },
            {
                dataField: "last_dividend_comments",
                displayName: "Comments"
            },

        ],
        [
            {
                dataField: "reit_investment_theme",
                displayName: "Reit Investment Theme"
            },
            {
                dataField: "reit_exposure",
                displayName: "Reit Exposure"
            },
            {
                dataField: "reit_type",
                displayName: "Reit Type"
            },
            {
                dataField: "reit_listing_date",
                displayName: "Reit Listing Date"
            },
        ],
        [
            {
                dataField: "lics_investment_theme",
                displayName: "LIC Investment Theme"
            },
            {
                dataField: "lics_exposure",
                displayName: "LIC Exposure"
            },
            {
                dataField: "lics_type",
                displayName: "LIC Type"
            },
            {
                dataField: "lics_listing_date",
                displayName: "LIC Listing Date"
            },
            {
                dataField: "lics_mer_percent",
                displayName: "LIC MER Percent"
            },
            {
                dataField: "lics_outperf_fee",
                displayName: "LIC Outperf Fee"
            },
        ],
    ],
    Announcement: [
        {
            dataField: "ticker",
            displayName: "Ticker"
        },
        {
            dataField: "market_sensitive",
            displayName: "Market Sensitive"
        },
        {
            dataField: "name",
            displayName: "Company Name"
        },
        {
            dataField: "release_date",
            displayName: "Release Date"
        },
        {
            dataField: "title",
            displayName: "Document Title"
        },
        {
            dataField: "url",
            displayName: "Document URL"
        }
    ],
    Company: [
        {
            dataField: "ticker",
            displayName: "Ticker"
        },
        {
            dataField: "name",
            displayName: "Company Name"
        },
        {
            dataField: "last_price",
            displayName: "Last Price"
        },
        {
            dataField: "market_cap",
            displayName: "Market Cap"
        },
        {
            dataField: "website",
            displayName: "Website"
        },
    ],
    Director: [
        {
            dataField: "full_name",
            displayName: "Name"
        },
        {
            dataField: "director_title",
            displayName: "Title"
        },
        {
            dataField: "ticker",
            displayName: "Ticker"
        },
        {
            dataField: "name",
            displayName: "Company"
        },
        {
            dataField: "year_born",
            displayName: "Year Born"
        },
        {
            dataField: "age",
            displayName: "Age"
        },
        {
            dataField: "total_pay",
            displayName: "Remuneration"
        },
        {
            dataField: "fiscal_year",
            displayName: "Fiscal Year"
        },
        {
            dataField: "exercised_value",
            displayName: "Exercised"
        },
        {
            dataField: "unexercised_value",
            displayName: "Unexercised"
        },
    ],
}

export const allIndices = [
    'S&P/ASX Emerging Companies Index',
    'S&P/ASX 300',
    'ALL ORDINARIES',
    'S&P/ASX SMALL ORDINARIES',
    'S&P/ASX 200',
    'S&P/ASX All Australian 200',
    'S&P/ASX 200 Consumer Staples (Sector)',
    'S&P/ASX Dividend Opportunities Index',
    'S&P/ASX 200 Financials (Sector)',
    'S&P/ASX 200 Financial-x-A-REIT (Sector)',
    'S&P/ASX 200 Industrials (Sector)',
    'S&P/ASX200 A-REIT (Sector)',
    'S&P/ASX 200 COMMUNICATION SERVICES (SECTOR)',
    'S&P/ASX 20',
    'S&P/ASX 50',
    'S&P/ASX 100',
    'S&P/ASX All Australian 50',
    'S&P/ASX 200 Materials (Sector)',
    'S&P/ASX 300 Metals and Mining (Industry)',
    'S&P/ASX 200 RESOURCES',
    'S&P/ASX MIDCAP 50',
    'S&P/ASX 200 Utilities (Sector)',
    'S&P/ASX 200 Information Technology (Sector)',
    'S&P/ASX 200 Energy (Sector)',
    'S&P/ASX 200 Health Care (Sector)',
    'S&P/ASX All Ordinaries Gold (Sub Industry)',
    'S&P/ASX 200 Consumer Discretionary (Sector)'
];

export const AllSearchTypes = [
    "Announcement Search",
    "Company Search",
    "Director Search",
];