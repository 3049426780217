// /client/App.js
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Router from './Router';
import GlobalStyles from '../styles';
import { ActionTypes } from "../store/types";
import { readLocalData } from '../localStorage';


const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        readLocalData().then((storedUser) => {
            if (!storedUser) return;
            dispatch({
                type: ActionTypes.ON_SIGN_IN,
                user: storedUser
            });
        });
    })

    return (
        <>
            <GlobalStyles />
            <Router />
        </>
    )
}

export default App;
