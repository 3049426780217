import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import styled from 'styled-components';

import Card from './Card'
import { AllSearchTypes } from '../../utils/consts'

export default () => {
    const isWaiting = useSelector(state => state.isWaiting);
    const searchType = useSelector(state => state.searchType);
    const [searchTypeIndex, setIndex] = useState(0);

    useEffect(() => {
        AllSearchTypes.map((type, index) => {
            if (searchType === type)
                setIndex(index);
        })
    }, [searchType]);
    const searchResult = useSelector(state => state.result[searchTypeIndex]);

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };

    return (
        <Container>
            {isWaiting
                ?
                < Loader >
                    <ScaleLoader
                        cssOverride={override}
                        size={50}
                        color={"var(--green-primary)"}
                        loading={isWaiting}
                        speedMultiplier={1}
                    />
                </Loader>
                : searchResult == undefined ?
                    <Loader>
                        <H5>Enter Search Terms Above......</H5>
                    </Loader>

                    // if no match found
                    : (searchResult.length === 0 ?
                        <Loader>
                            <H5>There are no results</H5>
                        </Loader>

                        // Show results
                        : searchResult.map((_data, index) => (
                            <Card
                                cardData={_data}
                                cardIndex={index}
                                key={index}
                            />
                        ))
                    )
            }
        </Container >
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: transparent;
    position: relative;
    gap: 20px;
`;
const H5 = styled.h5`
    color: var(--shade-1);
`;
const Loader = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 165px - 50px);
    justify-content: center;
    align-items: center;
    padding: 50px 0 0 0;
`;

