import { writeLocalData } from '../localStorage';
import { AllSearchTypes } from '../utils/consts';
import { ActionTypes } from './types';

export const init = {
    user: {
        isLogined: false,
        email: "",
        password: ""
    },
    isWaiting: false,       // Is waiting for backend response?
    searchType: AllSearchTypes[0],
    searchTerms: [[], [], []],
    searchOperator: ["AND", "AND", "AND"],  // "AND" or "OR"
    sortBy: ["none", "none", "none"],         // Sort by field
    sortOrder: ["Ascending", "Ascending", "Ascending"], // "Ascending" or "Descending"
    searchOptions: [[], [], []],      // Filtering Options
    result: [undefined, undefined, undefined],      // Searched Result
};

export default function reducer(state = init, action) {
    switch (action.type) {
        case ActionTypes.ON_SIGN_IN:
            {
                writeLocalData(action.user);
                return {
                    ...state,
                    user: action.user
                };
            }

        case ActionTypes.ON_SIGN_OUT:
            {
                writeLocalData(action.user);
                return {
                    ...state,
                    user: action.user
                };
            }

        case ActionTypes.ON_WAITING:
            {
                if (action.isWaiting === undefined) return state;

                return {
                    ...state,
                    isWaiting: action.isWaiting,
                };
            }

        case ActionTypes.ON_SET_SEARCH_TYPE:
            {
                return {
                    ...state,
                    searchType: action.searchType,
                };
            }

        case ActionTypes.ON_SEARCH:
            {
                if (!action.data) return state;

                let newResult = [];
                let newSearchTerms = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType) {
                        newResult.push(action.data.result);
                        newSearchTerms.push([...state.searchTerms[index], action.data.searchTerms])
                    }
                    else {
                        newResult.push(state.result[index]);
                        newSearchTerms.push(state.searchTerms[index]);
                    }
                })

                return {
                    ...state,
                    searchTerms: newSearchTerms,
                    result: newResult,
                };
            }

        case ActionTypes.ON_SEARCH_OPERATOR:
            {
                if (!action.searchOperator) return state;

                let newOperator = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType)
                        newOperator.push(action.searchOperator);
                    else newOperator.push(state.searchOperator[index]);
                })

                return {
                    ...state,
                    searchOperator: newOperator,
                };
            }

        case ActionTypes.ON_SORT_ORDER:
            {
                if (!action.sortOrder) return state;

                let newOrder = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType)
                        newOrder.push(action.sortOrder);
                    else newOrder.push(state.sortOrder[index]);
                })

                return {
                    ...state,
                    sortOrder: newOrder,
                };
            }

        case ActionTypes.ON_SORT_BY:
            {
                if (!action.sortBy) return state;

                let newSortBy = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType)
                        newSortBy.push(action.sortBy);
                    else newSortBy.push(state.sortBy[index]);
                })

                return {
                    ...state,
                    sortBy: newSortBy,
                };
            }

        case ActionTypes.ON_ADD_FILTER:
            {
                if (!action.filter) return state;

                let newSearchOptions = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType)
                        newSearchOptions.push([...state.searchOptions[index], action.filter]);
                    else newSearchOptions.push(state.searchOptions[index]);
                })

                return {
                    ...state,
                    searchOptions: newSearchOptions,
                };
            }
        case ActionTypes.ON_UPDATE_FILTER:
            {
                if (action.index === undefined) return state;

                let newSearchOptions = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType) {
                        let searchOptions = [...state.searchOptions[index]];
                        searchOptions[action.index] = action.searchOption;
                        newSearchOptions.push(searchOptions);
                    }
                    else newSearchOptions.push(state.searchOptions[index]);
                })

                return {
                    ...state,
                    searchOptions: newSearchOptions,
                };


            }

        case ActionTypes.ON_REMOVE_FILTER:
            {
                if (action.index === undefined) return state;

                let newSearchOptions = [];
                AllSearchTypes.map((type, index) => {
                    if (type === state.searchType) {
                        let searchOptions = [...state.searchOptions[index]];
                        searchOptions.splice(action.index, 1);
                        newSearchOptions.push(searchOptions);
                    }
                    else newSearchOptions.push(state.searchOptions[index]);
                })

                return {
                    ...state,
                    searchOptions: newSearchOptions,
                };
            }

        default:
            return state;
    }
}