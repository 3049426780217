import styled from "styled-components";

import SearchBar from '../SearchBar';
import Logout from "../Login/Logout";
import { tabletSmall } from "../../styles";

const Header = () => {
    return (
        <Container>
            <TitleBar>
                <Title>YourIndex Research</Title>
                <SignOut>
                    <Logout />
                </SignOut>
            </TitleBar>
            <SearchBar />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 165px;
    ${tabletSmall} {
        height: 200px;
    }
    justify-content: space-between;
    background-color: var(--green-primary);
    padding: 20px 20px 10px 20px;
`
const TitleBar = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--shade-5);
    font-weight: 800;
    position: relative;
`
const Title = styled.h1`
    color: var(--shade-5);
    font-weight: 800;
`
const SignOut = styled.div`
    position: absolute;
    right: 40px;
    ${tabletSmall} {
        right: 0;
    }
`

export default Header;