export const ActionTypes = {
    ON_SIGN_IN: "ON_SIGN_IN",
    ON_SIGN_OUT: "ON_SIGN_OUT",
    ON_WAITING: "ON_WAITING",
    ON_SEARCH: "ON_SEARCH",
    ON_SEARCH_OPERATOR: "ON_SEARCH_OPERATOR",
    ON_SORT_ORDER: "ON_SORT_ORDER",
    ON_SORT_BY: "ON_SORT_BY",
    ON_ADD_FILTER: "ON_ADD_FILTER",
    ON_UPDATE_FILTER: "ON_UPDATE_FILTER",
    ON_REMOVE_FILTER: "ON_REMOVE_FILTER",
    ON_SET_SEARCH_TYPE: "ON_SET_SEARCH_TYPE"
};