
export const exportCSV = (data, headers) => {
    const stringfyRow = (values) => {
        let ret = "";
        values.forEach((value, index) => ret += index === 0 ? `${value}` : `,${value}`);
        ret += "\n"
        return ret;
    };

    let exportData = "";
    const keys = headers.map(_headers => _headers.map(header => header.displayName));
    exportData = stringfyRow(keys);
    data.forEach((row) => {
        const inlineRow = [];
        headers.forEach((_headers) => {
            _headers.forEach((header) => {
                if (row[header.dataField] || row[header.dataField] === 0) {
                    if (row[header.dataField].constructor === Array) {
                        if (header.dataField === "indices") {
                            header.displayName.map(name => {
                                row[header.dataField].includes(name, 0) ?
                                    inlineRow.push("yes") :
                                    inlineRow.push("");
                            })
                        } else
                            inlineRow.push(`"${row[header.dataField].toString().replaceAll(",", "\n")}"`);
                    } else
                        inlineRow.push(row[header.dataField].toString().replaceAll(",", "").replaceAll("\n", " "));
                } else
                    inlineRow.push("");
            })

        })
        exportData += stringfyRow(inlineRow);
    })

    const fileName = new Date().toLocaleDateString()
    let responseFileName = decodeURI(
        fileName + ".csv"
    );
    let url = window.URL.createObjectURL(new Blob([exportData]));
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", responseFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const transformData = (data) => {
    const formatTime = (time) => {
        return new Date(time).toLocaleString(
            'en-US',
            {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }
        )
    }

    let tempData = [];
    data.map((_data) => {
        const dividend = _data.company_info.last_dividend || {};
        const share = _data.company_info.primary_share || {};
        const info = _data.company_info || {};

        let tempObj = {
            ticker:
                info.ticker,
            name:
                info.name,
            release_date:
                _data.release_date === "" ?
                    "" : formatTime(_data?.release_date),
            title:
                _data.title,
            url:
                _data.url,
            directors:
                Array.isArray(info?.directors) ?
                    info?.directors.map(item => item.full_name) : [],
            full_name:
                info?.director?.full_name,
            director_title:
                info?.director?.title,
            year_born:
                info?.director?.year_born,
            age:
                info?.director?.age,
            total_pay:
                info?.director?.total_pay,
            fiscal_year:
                info?.director?.fiscal_year,
            exercised_value:
                info?.director?.exercised_value,
            unexercised_value:
                info?.director?.unexercised_value,
            gics_industry:
                info.gics_industry,
            gics_sector:
                info.gics_sector,
            market_cap:
                share.market_cap === "" ?
                    "" : "$" + share.market_cap?.toLocaleString(),
            principal_activities:
                info.principal_activities,
            last_price:
                share.last_price === "" ?
                    "" : "$" + share.last_price?.toLocaleString(),
            last_trade_date:
                share.last_trade_date === "" ?
                    "" : formatTime(share.last_trade_date),
            listing_date:
                info.listing_date === "" ?
                    "" : formatTime(info.listing_date),
            delisting_date:
                info.delisting_date,
            website:
                info.website,
            type:
                share.type,
            day_volume:
                share.average_daily_volume?.toLocaleString(),
            pe: share.pe,
            eps: share.eps,
            securities_outstanding:
                share.securities_outstanding?.toLocaleString(),
            foreign_exempt:
                info.foreign_exempt,
            nums_of_lines:
                _data.nums_of_lines,
            has_dividends:
                info.has_dividends,
            market_sensitive:
                _data.market_sensitive?.toString(),
            open_price:
                share.open_price === "" ?
                    "" : "$" + share.open_price,
            bid_price:
                share.bid_price === "" ?
                    "" : "$" + share.bid_price,
            offer_price:
                share.offer_price === "" ?
                    "" : "$" + share.offer_price,
            day_high_price:
                share.day_high_price === "" ?
                    "" : "$" + share.day_high_price,
            day_low_price:
                share.day_low_price === "" ?
                    "" : "$" + share.day_low_price,
            day_change_price:
                share.day_change_price === "" ?
                    "" : "$" + share.day_change_price,
            day_change_percent:
                share.day_change_percent,
            prev_day_close_price:
                share.prev_day_close_price === "" ?
                    "" : "$" + share.prev_day_close_price,
            prev_day_change_percent:
                share.prev_day_change_percent,
            year_high_price:
                share.year_high_price === "" ?
                    "" : "$" + share.year_high_price,
            year_high_date:
                share.year_high_date === "" ?
                    "" : formatTime(share.year_high_date),
            year_low_price:
                share.year_low_price === "" ?
                    "" : "$" + share.year_low_price,
            year_low_date:
                share.year_low_date === "" ?
                    "" : formatTime(share.year_low_date),
            indices:
                share.indices?.map(item => item.name) || [],
            has_indices:
                info.has_indices,
            last_dividend_type:
                dividend.type,
            last_dividend_created_date:
                dividend.created_date === "" ?
                    "" : formatTime(dividend.created_date),
            last_dividend_ex_date:
                dividend.ex_date === "" ?
                    "" : formatTime(dividend.ex_date),
            last_dividend_payment_date:
                dividend.payable_date === "" ?
                    "" : formatTime(dividend.payable_date),
            last_dividend_record_date:
                dividend.record_date === "" ?
                    "" : formatTime(dividend.record_date),
            last_dividend_books_date:
                dividend.books_close_date === "" ?
                    "" : formatTime(dividend.books_close_date),
            last_dividend_amount_aud:
                dividend.amount_aud === "" ?
                    "" : "$" + dividend.amount_aud,
            last_dividend_franked_percent:
                dividend.franked_percent === "" ?
                    "" : dividend.franked_percent + "%",
            last_dividend_comments:
                dividend.comments,
            has_reit:
                info.has_reit,
            reit_investment_theme:
                info?.reit?.investment_theme,
            reit_exposure:
                info?.reit?.exposure,
            reit_type:
                info?.reit?.type,
            reit_Listing_date:
                info?.reit?.listing_date,
            has_lics:
                info?.has_lics,
            lics_investment_theme:
                info?.lics?.investment_theme,
            lics_exposure:
                info?.lics?.exposure,
            lics_type:
                info?.lics?.type,
            lics_benchmark:
                info?.lics?.benchmark,
            lics_mer_percent:
                info?.lics?.mer_percent === "" ?
                    "" : info?.lics?.mer_percent + "%",
            lics_listing_date:
                info?.lics?.listing_date,
            lics_outperf_fee:
                info?.lics?.outperf_fee,
            sub_industry: info.sub_industry,
            industry_group: info.industry_group,

        }
        tempData.push(tempObj);
    });

    return tempData;
}
