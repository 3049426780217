import styled from "styled-components";
import { useEffect, useRef, useState } from 'react';

const Selector = (props) => {
    const { title, selects, selectName, onSelect: onParentSelect, isModal, isSearchBar } = props;
    let [opened, setOpened] = useState(false);

    const selector = useRef(null);
    const onClickOutside = (e) => {
        if (!selector || !selector?.current) return;

        if (!selector.current.contains(e.target)) {
            setOpened(false);
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        }
    });

    const onSelect = (select) => {
        setOpened(!opened);
        onParentSelect(select);
    }

    return (
        <Container
            ref={selector}
            isModal={isModal}
        >
            <Title> {title} </Title>
            <Default
                onClick={() => setOpened(!opened)}
                isOpen={opened}
                isModal={isModal}
                isSearchBar={isSearchBar}
            >
                <Text>{selectName}</Text>
                {isSearchBar &&
                    <Icon src="img/down.svg" />
                }
            </Default>
            {opened &&
                <SelectList
                    isModal={isModal}
                    isSearchBar={isSearchBar}
                >
                    {selects && selects.map((select) =>
                        <Select
                            onClick={() => onSelect(select)}
                            key={select}
                            isModal={isModal}
                        >
                            <Text>{select}</Text>
                        </Select>
                    )}
                </SelectList>
            }
        </Container>
    );
}

const Container = styled.div`
    user-select: none;
    display: block;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    color: ${({ isModal }) => isModal ? "#000" : "#fff"};
`
const Title = styled.div`
    font-weight: 400;
    font-size: 16px;
    padding-left: 15px;
`
const Default = styled.div`
    z-index: 100;
    cursor: pointer;  
    display: flex;
    flex-direction: row;
    height: 30px;
    border: ${({ isSearchBar }) => isSearchBar ? "0px" : "1px"} solid ${({ isModal }) => isModal ? "#000" : "#fff"};
    box-sizing: border-box;
    border-radius: 15px;
    background-color: ${({ isModal }) => isModal ? "#fff" : "transparent"};
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom-width: ${({ isOpen, isSearchBar }) => isOpen || isSearchBar ? "0px" : "1px"};
    border-bottom-left-radius: ${({ isOpen, isSearchBar }) => isOpen && !isSearchBar ? "0px" : "15px"};
    border-bottom-right-radius: ${({ isOpen, isSearchBar }) => isOpen && !isSearchBar ? "0px" : "15px"};
    font-size: ${({ isSearchBar }) => isSearchBar ? "14px" : "16px"};
    gap: 8px;
`
const Text = styled.div`
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
`
const SelectList = styled.div`
    z-index: 100;
    position: absolute;
    height: auto;
    width: ${({ isSearchBar }) => isSearchBar ? "180px" : "100%"};
    display: block;
    box-sizing: border-box;
    background-color: ${({ isModal }) => isModal ? "#fff" : "var(--green-primary)"};
    border: 1px solid ${({ isModal }) => isModal ? "#000" : "#fff"};
    border-radius: 15px;
    padding: 10px 0;
    border-top-left-radius: ${({ isSearchBar }) => isSearchBar ? "15px" : "0px"};
    border-top-right-radius: ${({ isSearchBar }) => isSearchBar ? "15px" : "0px"};
    margin-top: ${({ isSearchBar }) => isSearchBar ? "10px" : "0px"};
`
const Select = styled.div`
    cursor: pointer;  
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 25px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    padding: 0 15px;
    margin: 0;
    gap: 5px;
    :hover {
        color: ${({ isModal }) => isModal ? "#aaa" : "#000"};
    }
`
const Icon = styled.img`
    user-select: none;
    cursor: select;
    width: 12px;
    height: 12px;
`

export default Selector;