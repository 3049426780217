import styled from 'styled-components';

const Input = (props) => {
    const { title, onChange, value, type, disabled } = props;
    return (
        <Container>
            <Title>
                {title}
            </Title>
            <Content
                onChange={onChange}
                value={value}
                type={type}
                disabled={disabled}
            />
        </Container>
    );
}

const Container = styled.div`
	display: flex;
    flex-direction: column;
    width: 100%;
`
const Content = styled.input`
	box-sizing: border-box;
	border: 1px solid #000;
	border-radius: 15px;
	font-weight: 400;
	font-size: 16px;
	width: 100%;
    height: 30px;
	padding: 5px 15px;
`
const Title = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #000;
    padding: 0 15px;
`

export default Input;