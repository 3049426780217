import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Header from '../../component/Header';
import SearchOptions from '../../component/SearchOptions';
import SearchResult from '../../component/SearchResult';
import { Login } from '../../component/Login';
import { tabletSmall } from '../../styles';

const Home = () => {
    const isLogined = useSelector(state => state.user.isLogined);
    return (
        <>
            {!isLogined ?
                <Login isAdmin={false} /> :
                <Container>
                    <Header />
                    <Body>
                        <LeftBar>
                            <SearchOptions />
                        </LeftBar>
                        <RightBar>
                            <>
                                <ShadowBar>
                                    <Shadow />
                                </ShadowBar>
                                <Rectangle />
                            </>
                            <SearchResult />
                        </RightBar>
                    </Body>
                </Container>
            }
        </>
    )
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
`;
const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;
const LeftBar = styled.div`
    ${tabletSmall} {
        display: none;
    }
    width: 350px;
    height: calc(100vh - 165px);
    box-sizing: border-box;
`;
const RightBar = styled.div`
    width: calc(100vw - 350px);
    ${tabletSmall} {
        width: 100%;
        padding: 0 20px;
        height: calc(100vh - 200px - 10px);
    }
    height: calc(100vh - 165px - 10px);
    overflow: auto;
    box-sizing: border-box;
    padding: 0 60px 0 30px;
    margin-top: 10px;
    ::-webkit-scrollbar{
        width: 8px;
        height: 4px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0) inset;
    }
    ::-webkit-scrollbar-thumb{
        border-radius: 4px;
        background: #c7c4c4;
    }
`;
const ShadowBar = styled.div`
    position: fixed;
    top: 165px;
    right: 0;
    width: calc(100vw - 350px);
    ${tabletSmall} {
        width: 100%;
        padding: 0 20px;
        border-radius: 0 0 16px 16px;
        top: 200px;
    }
    height: 50px;
    border-radius: 0 0 16px 0;
    background-color: var(--green-primary);
    z-index: -1;
    padding: 0 80px 0 50px;
`;
const Shadow = styled.div`
    height: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.6);
`;
const Rectangle = styled.div`
    position: fixed;
    top: 165px;
    right: 0;
    width: calc(100vw - 350px);
    ${tabletSmall} {
        width: 100%;
        top: 200px;
    }
    height: 10px;
    background-color: var(--green-primary);
    z-index: -1;
`;

export default Home;
