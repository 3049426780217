import { createGlobalStyle } from 'styled-components';

export const HD = `@media screen and (min-width: 1800px)`;
export const desktop = `@media screen and (max-width: 1439px)`;
export const tabletLarge = `@media screen and (max-width: 1080px)`;
export const tablet = `@media screen and (max-width: 1023px)`;
export const tabletMedium = `@media screen and (max-width: 920px)`;
export const tabletSmall = `@media screen and (max-width: 820px)`;
export const mobile = `@media screen and (max-width: 767px)`;
export const mobileSmall = `@media screen and (max-width: 374px)`;

const GlobalStyles = createGlobalStyle`
    : root {
        --shade-0: #000;
        --shade-1: #525252;
        --shede-2: #949494;
        --shade-3: #C1C1C1;
        --shade-4: #E8E8E8;
        --shade-5: #E8FFE2;
        --shade-6: #EFF4ED;
        --shade-7: #fff;

        --green-primary: #4F7942;
        --green-secondary: #A4CD32;

        --purple-primary: #84466B;

        --sign-button-primary: #047d95;
        --sign-border: #838c95;
        --admin-cardbody-primary: #ccc;

        @font-face {
            font-family: kanit;
            src: url("/font/Kanit-Regular.ttf");
        }
    }

    body {
        background-color: var(--shade-7);
        font-family: Kanit;
    }
`;

export default GlobalStyles;
