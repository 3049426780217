import styled from 'styled-components';

const Button = (props) => {
    const { img, bg, size, onClick } = props;
    return (
        <Container
            bg={bg}
            onClick={onClick}
            {...props}>
            <Img
                size={size}
                src={img}
            />
        </Container>
    );
}
export default Button;

const Container = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: ${({ bg }) => bg ? bg : "var(--shade-7)"};
    cursor: pointer;
`
const Img = styled.img`
    width: ${({ size }) => size ? size : "18px"};
`