import { useRoutes } from 'react-router-dom';
import styled from 'styled-components';

import { Home, Admin } from '../pages'

const getRoutes = () => {
    const routes = [
        {
            path: '/',
            element: <Home />,
            children: []
        },
        {
            path: '/home',
            element: <Home />,
            children: []
        },
        {
            path: '/admin',
            element: <Admin />,
            children: []
        }
    ];

    return routes;
}

const Router = () => {
    const routes = getRoutes();
    const routing = useRoutes(routes);

    return (
        <Content>
            {routing}
        </Content>
    )
}

const Content = styled("div")`
    margin-top: 0px;
`;

export default Router;