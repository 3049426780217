import { useDispatch } from "react-redux";
import styled from 'styled-components';

import { ActionTypes } from "../../../store/types";

const FilterFactory = (title, component, index) => {

    return (
        <Container>
            <Header>
                <span>{title}</span>
                <CloseButton index={index} />
            </Header>
            {component}
        </Container>
    )
}

const CloseButton = (props) => {
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch({
            type: ActionTypes.ON_REMOVE_FILTER,
            index: props.index
        });
    }

    return (
        <ButtonEffect onClick={onClose}>
            <img
                src="img/close.svg"
                style={{ "width": "15px" }}
                alt=""
            />
        </ButtonEffect>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px dotted #fff;
    border-radius: 15px;
    padding: 10px;
    margin: 0;
    color: #fff;
    gap: 10px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`

const ButtonEffect = styled.div`
    cursor: pointer;  
    :hover {
        filter: brightness(80%);
    }

    :active {
        filter: brightness(50%);
    }
`;
export default FilterFactory;