import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components'

import Selector from '../../Selector';
import { ActionTypes } from '../../../store/types';
import FilterFactory from './FilterFactory';

export const BooleanCard = (props) => {
    const { index, data } = props;
    const dispatch = useDispatch();
    const component = (
        <Body>
            <span>Yes / No</span>
            <div onClick={() => dispatch({
                type: ActionTypes.ON_UPDATE_FILTER,
                index: index,
                searchOption: {
                    ...data,
                    value: !data.value,
                }

            })}
            >
                {data.value ?
                    <img
                        src="img/switch-yes.svg"
                        alt=""
                    /> :
                    <img
                        src="img/switch-no.svg"
                        alt=""
                    />
                }
            </div>
        </Body>
    );

    return FilterFactory(data.displayName, component, index);
}

export const RangeCard = (props) => {
    const { index, data } = props;
    const dispatch = useDispatch();
    const component = (
        <Body>
            <Input value={data.rangeStart} onChange={e => dispatch({
                type: ActionTypes.ON_UPDATE_FILTER,
                index: index,
                searchOption: {
                    ...data,
                    rangeStart: e.target.value
                }
            })}
            />
            <span>To</span>
            <Input value={data.rangeEnd} onChange={e => dispatch({
                type: ActionTypes.ON_UPDATE_FILTER,
                index: index,
                searchOption: {
                    ...data,
                    rangeEnd: e.target.value
                }
            })}
            />
        </Body>
    );

    return FilterFactory(data.displayName, component, index);
}
export const DateRangeCard = (props) => {
    const { index, data } = props;
    const dispatch = useDispatch();
    const component = (
        <Body>
            <Input type="date" value={data.rangeStart} onChange={e => dispatch({
                type: ActionTypes.ON_UPDATE_FILTER,
                index: index,
                searchOption: {
                    ...data,
                    rangeStart: e.target.value
                }
            })}
            />
            <span>To</span>
            <Input type="date" value={data.rangeEnd} onChange={e => dispatch({
                type: ActionTypes.ON_UPDATE_FILTER,
                index: index,
                searchOption: {
                    ...data,
                    rangeEnd: e.target.value
                }
            })}
            />
        </Body>
    );

    return FilterFactory(data.displayName, component, index);
}

export const SelectCard = (props) => {
    const { index, data } = props;
    const dispatch = useDispatch();
    const component = (
        <Body>
            <Selector
                title=""
                selects={data.selects}
                selectName={data.value}
                onSelect={selectName => dispatch({
                    type: ActionTypes.ON_UPDATE_FILTER,
                    index: index,
                    searchOption: {
                        ...data,
                        value: selectName
                    }
                })}
            />
        </Body>
    );

    return FilterFactory(data.displayName, component, index);
}

const Body = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`
const Input = styled.input`
    width: 45%;
    height: 25px;
    font-size: 14px;
    border-radius: 15px;
    padding: 0 10px;
    border 0px;
`
